import {
  BlogIndexPage,
  blogIndexGetStaticProps,
  BlogIndexPageProps,
} from "@octopus-energy/storyblok-cms";
import type { GetStaticProps, NextPage } from "next";
import { Layout } from "@core/layout";

const Template: NextPage<BlogIndexPageProps> = (props) => (
  <Layout>
    <BlogIndexPage {...props} />
  </Layout>
);

export default Template;

export const getStaticProps: GetStaticProps<BlogIndexPageProps> =
  blogIndexGetStaticProps;
